<template>
  <Page title="myGymer-Angebote" color="success">
    <v-card flat class="mb-4">
      <v-card-text>
        <v-progress-linear :value="percentage" color="info" height="25"
          >{{ percentage }}%</v-progress-linear
        >
        {{ statistics.confirmedCount }} von
        {{ statistics.needToConfirmCount + statistics.confirmedCount }}
        Schülerinnen und Schülern haben die Wahl ihrer myGymer-Angebote
        bestätigt.
      </v-card-text>
    </v-card>
    <v-card>
      <v-system-bar>myGymer-Angebote</v-system-bar>
      <v-data-table
        :items="items"
        :headers="headers"
        :search="search"
        hide-default-footer
        :items-per-page="-1"
        disable-sort
        @click:row="(el) => showDetails(el)"
        :item-class="() => 'clickable'"
      >
        <template v-slot:header.gradeNow.description="{}">
          {{
            statistics.currentTerm ? statistics.currentTerm.shortText : "Stufe"
          }}
        </template>
        <template v-slot:header.gradeNext.description="{}">
          {{ statistics.nextTerm ? statistics.nextTerm.shortText : "Stufe" }}
        </template>
      </v-data-table>
      <router-view />
    </v-card>
  </Page>
</template>

<script>
import { defineComponent } from "vue";
export default defineComponent({
  name: "OptionalAvailability",
  props: ["search"],
  data() {
    return {
      statistics: {},
      items: [],
      headers: [
        {
          text: "Fach",
          value: "subject.description",
        },
        { text: "Stufe", value: "gradeNow.description" },
        { text: "Teilnehmer", value: "countNow" },
        { text: "Stufe", value: "gradeNext.description" },
        { text: "Anmeldungen", value: "countNext" },
      ],
    };
  },
  computed: {
    percentage() {
      return parseInt(
        (100 * this.statistics.confirmedCount) /
          (this.statistics.needToConfirmCount + this.statistics.confirmedCount)
      );
    },
  },
  methods: {
    showDetails(el) {
      this.$router.push({
        name: "OptionalStatisticsSubject",
        params: { subject: el.subject.id },
      });
    },
  },
  async created() {
    this.statistics = await this.apiList({ resource: "optional/statistics" });
    this.items = this.statistics.optionalSubjects.sort((a, b) => {
      if (a.subject.description > b.subject.description) {
        return 1;
      }
      if (a.subject.description < b.subject.description) {
        return -1;
      }
      if (a.gradeNow && b.gradeNow && a.gradeNow.id > b.gradeNow.id) {
        return -1;
      }
      if (a.gradeNow && b.gradeNow && a.gradeNow.id < b.gradeNow.id) {
        return 1;
      }
      if (a.gradeNext && b.gradeNext && a.gradeNext.id > b.gradeNext.id) {
        return -1;
      }
      if (a.gradeNext && b.gradeNext && a.gradeNext.id < b.gradeNext.id) {
        return 1;
      }
    });
  },
});
</script>
